import React from 'react';
import loadable from '@loadable/component';

const AnswerInputV1 = loadable(() => import('./AnswerInputV1'));
const AnswerInputV2 = loadable(() => import('./AnswerInputV2'));

const AnswerInput = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <AnswerInputV2 {...props} />;
    default:
    case 'version1':
      return <AnswerInputV1 {...props} />;
  }
};

export default AnswerInput;
